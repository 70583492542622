import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { LoaderService } from './loader.service';

@Component({
  selector: 'ui-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoaderComponent {

  constructor(@Inject(LoaderService) public loader: LoaderService) { }

}
