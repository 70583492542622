import {ThemePalette} from "@angular/material/core";

export class ModalConfirmarData
{
    public mensagem: string;
    public corNao?: ThemePalette;
    public corSim?: ThemePalette;
    public entity?: any;
    public confirmar: (entity: any) => any;
    public cancelar: (entity: any) => any;

}
