<mat-form-field>
  <div>
    <mat-toolbar>
      <input matInput [(ngModel)]="fileAttr" readonly name="name" />
      <button mat-flat-button color="primary" (click)="fileInput.click()">
        {{btnTexto}}
      </button>
    </mat-toolbar>
    <input
      type="file"
      #fileInput
      id="uploadFile"
      (change)="callUploadService($event)"
      name="uploadFile"
      [accept]="tiposAceitos"
    />
  </div>
</mat-form-field>
