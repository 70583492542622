<mat-menu #actionsMenu="matMenu" yPosition="above">
    <ng-template matMenuContent let-item="item">
        <div *ngFor="let option of config.actions">
            <div *ngIf="!option.esconder">
                <button
                    id="option"
                    mat-flat-button
                    (click)="option.action(item)"
                    [disabled]="option.desabilitar">
                    <div class="btn-icon-text">
                        <mat-icon id="opt" *ngIf="option.icone" style="margin-left: 1rem;" [color]="option.corIcone">{{option.icone}}</mat-icon>
                        <span>{{option.title}}</span>
                    </div>
                </button>
            </div>
        </div>
    </ng-template>
</mat-menu>

<div class="d-flex align-items-end flex-column">
    <div class="mt-auto p-2">
        <button mat-mini-fab class="btn-menu" matTooltip="Opções de ações" color="primary" [matMenuTriggerFor]="actionsMenu">
            <mat-icon>menu</mat-icon>
        </button>
    </div>
</div>
