import { AfterViewInit, Component, EventEmitter, Input, Output } from "@angular/core";
import { GridConfig } from "../grid";
import { DatePipe } from "@angular/common";
import { QueryDadoEntity, QueryEntity, RelatorioQueryCabecalhoEntity } from "./grid-generic.model";
import { Observable, Subscription } from "rxjs";
import { TextField } from "../form";
import { tap } from "rxjs/operators";
import { Meta } from "@angular/platform-browser";

@Component({
    selector: "ui-grid-generic",
    templateUrl: "./grid-generic.component.html",
    styleUrls: ["./grid-generic.component.scss"],
    providers: [DatePipe],
})
export class GridGenericComponent implements AfterViewInit {
    @Input() dadosConsulta$: Observable<QueryEntity[]>;
    @Input() cabecalhosInput: RelatorioQueryCabecalhoEntity[];
    @Input() meta?: Meta;
    @Output() metaChanged = new EventEmitter<Meta>();
    
    private subscriptions: Subscription[] = [];
    dadosConsulta: QueryEntity[] = [];
    valorDadosConsulta: QueryDadoEntity[] = [];
    cabecalhoDadosConsulta: QueryDadoEntity[];
    config: GridConfig;

    constructor(private datePipe: DatePipe) {}

    ngAfterViewInit(): void {
        this.montaGrid();

        this.subscriptions.push(
            this.dadosConsulta$
                .pipe(
                    tap((result) => {
                        if (result === undefined || result.length <= 0)
                            return this.montaGrid();

                        this.dadosConsulta = result;

                        this.montaEstruturaDados();
                        this.montaGridDinamica();
                    }),
                )
                .subscribe(),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) =>
            subscription.unsubscribe(),
        );
    }

    montaGrid() {
        this.config = {
            fields: [],
        } as GridConfig<QueryEntity>;
    }

    montaGridDinamica() {
        const camposGrid = [];

        this.cabecalhoDadosConsulta.forEach((campo) => {
            let campoGrid: TextField;

            campoGrid = new TextField({
                label: campo.nome,
                property: campo.coluna,
                validations: [],
                value: (x: QueryDadoEntity) => this.resolveValor(campo, x),
                esconder: campo.tipo === "Guid",
            });

            camposGrid.push(campoGrid);
        });

        this.config = {
            fields: camposGrid,
        } as GridConfig<QueryEntity>;
    }

    resolveValor(campo: QueryDadoEntity, x: QueryDadoEntity) {
        if (campo.tipo === "Boolean") {
            return x[campo.coluna] === "True" ? "Sim" : "Não";
        }

        return x[campo.coluna];
    }
        

    montaEstruturaDados() {
        const cabecalhos = [];

        if(this.cabecalhosInput && this.cabecalhosInput.length > 0) {
            for (const dado of this.cabecalhosInput) {
                cabecalhos.push({
                    tipo: dado.tipo,
                    coluna: dado.coluna,
                    nome: dado.nomeApresentacao
                });
            }

        } else {
            for (const dado of this.dadosConsulta[0].dados) {
                cabecalhos.push({
                    tipo: dado.tipo,
                    coluna: dado.coluna,
                    nome: dado.coluna
                });
            }
        }

        this.cabecalhoDadosConsulta = cabecalhos;

        const valores = [];

        for (const linha of this.dadosConsulta) {
            let dadosLinha = {};

            linha.dados.forEach((dado: QueryDadoEntity) => {
                dadosLinha = {
                    ...dadosLinha,
                    [dado.coluna]: dado.valor,
                };
            });

            valores.push(dadosLinha);
        }

        this.valorDadosConsulta = valores;
    }
}
