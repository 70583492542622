import { HttpClient, HttpParams } from '@angular/common/http';
import { Meta } from './query-params';
import { ApiResult, ListaPaginada } from './response';
import { EnumObjectModel } from "../../master/models/enum-object.model";
import { StringHelper } from "../../helpers";

export const API_URL = 'API_URL_BASE';
//TODO Remover dependência direta de módulos
export const IDENTIDADE_API_URL = 'IDENTIDADE_API_URL';
export const OPERACAO_API_URL = 'OPERACAO_API_URL';

export abstract class CrudService<T> {

    protected abstract url: string;
    protected parentUrl!: () => string;

    constructor(
        protected urlBase: string,
        protected httpClient: HttpClient
    ) {
    }

    public getWithQuery = (meta: Meta, params?: HttpParams) => {
        return this.getWithQueryBase<T>(this.url, meta, params);
    };

    protected getWithQueryBase<T>(url: string, meta: Meta, params?: HttpParams) {
        if(meta.filtro) {
            meta = {...meta, filtro: StringHelper.filtroTranslate(meta.filtro)}
        }

        if(!params){
            params = new HttpParams();
        }

        const metasOrdenacao = ['ordenacao', 'sentido'];
        params = Object.keys(meta)
            .filter(key => !metasOrdenacao.includes(key))
            .reduce((t: any, x: string) => {
                params = params.append(x, encodeURIComponent(meta[x as keyof Meta] as string));
                return params;
            }, {});

        if (meta.ordenacao) {
            params = params.append('ordenacao', this.retornaOrdenacao(meta || {}));
        }

        return this.httpClient.get<ApiResult<ListaPaginada<T>>>(url, { params });
    }

    retornaOrdenacao = ({ ordenacao, sentido = 'asc' }: Meta): string => {
        const parametros = ordenacao!.split(',');
        if (parametros.length === 1) {
            return `${ordenacao} ${sentido}`;
        } else {
            return parametros.map(x => `${x} ${sentido}`).join(',');
        }
    };

    public get = (params?: HttpParams) => this.httpClient.get<ApiResult<ListaPaginada<T>>>(this.url, { params });

    public getById = (id: any, params?: HttpParams) => this.httpClient.get<ApiResult<T>>(this.apiUrlWithId(id), { params });

    public count = (filter?: string) => this.httpClient.get<ApiResult<number>>(`${this.url}/count`, this.createFilterParam(filter));

    public create = (record: T) => this.httpClient.post<ApiResult<T>>(this.url, record);

    public createWithFormData = (record: FormData) => this.httpClient.post<T>(this.url, record);

    public update = (id: any, record: T) => this.httpClient.put<ApiResult<T>>(this.apiUrlWithId(id), record);

    public updateWithFormData = (id: any, record: FormData) => this.httpClient.put<T>(this.apiUrlWithId(id), record);

    public remove = (id: any) => this.httpClient.delete<T>(this.apiUrlWithId(id));

    public getEnum = (enumType: string) => this.httpClient.get<ApiResult<EnumObjectModel>>(`${this.urlBase}/v1/shared/enums/${enumType}`);

    protected apiUrlWithId = (id: any) => `${this.url}/${id}`;

    protected createFilterParam = (param?: string) => param ? ({ params: { filtro: param } }) : undefined;
}
