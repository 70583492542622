export const TIPO_RFB_LISTA_ESTATICA = 'LISTA_ESTATICA';

export const TIPO_ARQUIVO_EXCEL = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
export const TIPO_ARQUIVO_JSON = 'application/json';

export const IDENTIDADE_API_URL = 'IDENTIDADE_API_URL';
export const CONFIGURACAO_API_URL = 'CONFIGURACAO_API_URL';
export const OPERACAO_API_URL = 'OPERACAO_API_URL';
export const AUTENTICACAO_PORTAL_API_URL = 'AUTENTICACAO_PORTAL_API_URL';
export const PROCESSADOR_SOLICITACAO_URL = 'PROCESSADOR_SOLICITACAO_URL';

