import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
    ApiResult,
    CrudService,
    isNotNullOrUndefined,
    LocalStorageUtil
} from 'ui-shared';
import { CertificadoExisteResult, UsuarioCertificadoEntity, UsuarioCertificadoResult } from '@catalogo/models';
import { tap } from 'rxjs/operators';
import { AUTENTICACAO_PORTAL_API_URL, OPERACAO_API_URL } from "@catalogo/features/compartilhado/const/constantes";

@Injectable({ providedIn: 'any' })
export class UsuarioCertificadoDataService extends CrudService<UsuarioCertificadoEntity> {
    
    protected url: string;
    protected urlAutenticacao: string;
    
    constructor(
        @Inject(OPERACAO_API_URL) urlBase: string,
        @Inject(AUTENTICACAO_PORTAL_API_URL) urlAutenticacao: string,
        @Inject(HttpClient) protected httpClient: HttpClient
    ) {
        super(urlBase, httpClient);
        this.url = `${urlBase}/v1/usuariocertificado`;
        this.urlAutenticacao = urlAutenticacao;
        
        if(isNotNullOrUndefined(urlAutenticacao)){
            this.urlAutenticacao = this.urlBase
        }
    }
    
    public existe() {
        return this.httpClient.get<ApiResult<CertificadoExisteResult>>(this.url + '/existe');
    }
    
    public validar(senha: string) {
        return this.httpClient.post<ApiResult<UsuarioCertificadoResult>>(`${this.url}/validar`, { senha });
    }
    
    public upload(formData) {
        return this.httpClient.post<ApiResult<any>>(this.url, formData, {
            reportProgress: true,
            observe: 'events'
        });
    }
    
    public autenticar(certificadoPadrao: boolean, senha?: string) {
        return this.httpClient.post<ApiResult>(`${this.urlAutenticacao}/v1/AutenticacaoPortal/Autenticar`,
            {
                senhaCertificado: senha,
                certificadoPadrao: certificadoPadrao
            })
            .pipe(tap((r) => {
                if (!r.sucesso){
                    let msgErro = "Não foi possível autenticar.";
                    if(r.erros){
                        msgErro = r.erros[0];
                    }
                    
                    throw new Error(msgErro);
                }
                
                LocalStorageUtil.salvarDadosPortalUnico(r.data);
            }))
    }
}

