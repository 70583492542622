import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {ModalVisualizarData} from "./modal-visualizar-data";

@Component({
    templateUrl: './modal-visualizar.component.html',
    styleUrls: ['./modal-visualizar.component.scss']
})
export class ModalVisualizarComponent {
    public data: ModalVisualizarData;

    constructor(
        @Inject(MAT_DIALOG_DATA) private dataDialog: ModalVisualizarData
    ) {
        this.data = dataDialog;
    }
}
