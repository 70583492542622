import { Selector } from '@ngxs/store';
import { NotificacaoModel } from '../notificacao.model';
import { NotificacaoStateModel } from './notificacao-state-model';
import { NotificacaoState } from './notificacao.state';

export class NotificacaoSelectors {

  @Selector([NotificacaoState])
  public static notificacoes({ notificacoes }: NotificacaoStateModel): NotificacaoModel[] {
    return notificacoes;
  }
}
