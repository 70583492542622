import { GridGenericComponent } from './grid-generic.component';
import { NgModule } from '@angular/core';
import { GridModule } from '../grid/grid.module';
import { CommonModule } from '@angular/common';



@NgModule({
  declarations: [
    GridGenericComponent,
  ],
  imports: [
    GridModule,
    CommonModule
  ],
  exports: [
    GridGenericComponent
  ],
})
export class GridGenericModule { }
