import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgxsStoreModule } from './store/store.module';
import { AppComponent } from './app.component';
import { environment } from 'environments/environment';
import { registerLocaleData } from '@angular/common';
import ptBr from '@angular/common/locales/pt';
import {
    API_URL,
    AutoCompleteService,
    ButtonsModule,
    ErrorHttpInterceptor,
    FormModule,
    GridModule,
    JWTInterceptor,
    LoaderInterceptor,
    MasterModule,
    matFormField,
    matSnackbar
} from 'ui-shared';
import { MatDialogModule } from '@angular/material/dialog';
import { LoginComponent } from './features/compartilhado/login/login.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from "@angular/material/icon";
import { PublicoComponent } from './features/publico/publico.component';
import {
    PaginaNaoEncontradaComponent
} from './features/compartilhado/pagina-nao-encontrada/pagina-nao-encontrada.component';
import { MatRadioModule } from '@angular/material/radio';
import {
    AUTENTICACAO_PORTAL_API_URL,
    CONFIGURACAO_API_URL,
    IDENTIDADE_API_URL,
    OPERACAO_API_URL,
    PROCESSADOR_SOLICITACAO_URL
} from "./features/compartilhado/const/constantes";
import {
    UsuarioCertificadoModalRemoverComponent,
    UsuarioCertificadoModalSenhaComponent
} from "@catalogo/features/compartilhado/usuario-certificado";

registerLocaleData(ptBr);

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        UsuarioCertificadoModalSenhaComponent,
        UsuarioCertificadoModalRemoverComponent,
        PublicoComponent,
        PaginaNaoEncontradaComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        NgxsStoreModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        MasterModule,
        FormModule,
        GridModule,
        ButtonsModule,
        MatDialogModule,
        MatSnackBarModule,
        MatAutocompleteModule,
        MatInputModule,
        MatFormFieldModule,
        MatButtonModule,
        MatIconModule,
        MatRadioModule
    ],
    providers: [
        { provide: API_URL, useValue: environment.catalogoConfiguracao.apiUrl, multi: true },
        { provide: CONFIGURACAO_API_URL, useValue: environment.catalogoConfiguracao.apiUrl, multi: true },
        { provide: OPERACAO_API_URL, useValue: environment.catalogoOperacao.apiUrl, multi: true },
        { provide: PROCESSADOR_SOLICITACAO_URL, useValue: environment.processadorSolicitacao.apiUrl, multi: true },
        { provide: AUTENTICACAO_PORTAL_API_URL, useValue: environment.catalogoOperacao.autenticacaoPortalUnico, multi: true },
        { provide: IDENTIDADE_API_URL, useValue: environment.identidade.apiUrl, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: JWTInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorHttpInterceptor, multi: true },
        { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: matSnackbar() },
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: matFormField() },
        { provide: AutoCompleteService, useValue: environment.catalogoConfiguracao.apiUrl, multi: true },
        { provide: LOCALE_ID, useValue: 'pt-BR' }
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
