import {ChangeDetectionStrategy, Component, ElementRef, Input} from '@angular/core';
import {FocusMonitor} from '@angular/cdk/a11y';
import {BaseFieldDirective} from '../base-field.directive';
import {TextField} from './TextField';

@Component({
  selector: 'ui-text-field',
  templateUrl: './text-field.component.html',
  styleUrls: ['./text-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextFieldComponent extends BaseFieldDirective {

  @Input() config!: TextField;
  public esconderSenha: boolean = true;
  constructor(protected fmRef: FocusMonitor, protected elRef: ElementRef<HTMLElement>) {
    super(fmRef, elRef);
  }
}
