import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AutoCompleteService {
  public apiUrl: string = '';

  constructor(
  ) { }

}
