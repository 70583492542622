import { Component, ElementRef, Input } from '@angular/core';
import { FocusMonitor } from '@angular/cdk/a11y';
import { BaseFieldDirective } from '../base-field.directive';
import { DateField } from './DateField';

@Component({
  selector: 'ui-date-field',
  templateUrl: './date-field.component.html',
  styleUrls: ['./date-field.component.scss'],
})
export class DateFieldComponent extends BaseFieldDirective {

  @Input() config!: DateField;

  constructor(protected fmRef: FocusMonitor, protected elRef: ElementRef<HTMLElement>) {
    super(fmRef, elRef);
  }
}
