import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { FabConfig } from './floating-action-button.model';

@Component({
    selector: 'ui-fab',
    templateUrl: './floating-action-button.component.html',
    styleUrls: ['./floating-action-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FloatingActionButtonComponent implements OnInit {
    @Input() config!: FabConfig;

    constructor() { }

    ngOnInit(): void {
    }
}
