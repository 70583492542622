import { FormGroup } from '@angular/forms';
import { Component, Input, OnInit, ElementRef, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ui-button-primary',
  templateUrl: './button-primary.component.html',
  styleUrls: ['./button-primary.component.scss']
})
export class ButtonPrimaryComponent implements OnInit {

  @Input() formGroup!: FormGroup;
  @Output() clicked = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }


}
