import { PdmAtributoValorSelecionado, SaneamentoAtributoEntity } from '@catalogo/models';
import { FiltroSubmit, Meta } from 'ui-shared';

export class ObterTodos {
    public static readonly type = '[SaneamentoAtributo] Obter todos';
    constructor(public payload: { pdmVersaoId?: string; meta?: Meta }) { }
}
export class ObterTodosAtributos {
    public static readonly type = '[SaneamentoAtributo] Obter todos atributos';
    constructor(public pdmVersaoId?: string) { }
}

export class DefinirPdmVersao {
    public static readonly type = '[SaneamentoAtributo] Definir Pdm Versao';
    constructor(public pdmVersaoId: string) { }
}

export class SelecionarItem {
    public static readonly type = '[SaneamentoAtributo] Selecionar Item';
    constructor(public payload: { data?: any }) { }
}

export class SelecionarTodos {
    public static readonly type = '[SaneamentoAtributo] Selecionar Todos';
    constructor(public payload: { data?: boolean }) { }
}

export class AtualizarFiltrosSelecionados {
    public static readonly type = '[SaneamentoAtributo] Obter por filtro';
    constructor(public payload: FiltroSubmit) { }
}

export class Salvar {
    public static readonly type = '[SaneamentoAtributo] Salvar atributos';
    constructor(public payload: SaneamentoAtributoEntity[]) { }
}

export class SalvarRetornoNovos {
    public static readonly type = '[SaneamentoAtributo] Salvar atributos apos retorno novos';
    constructor(public payload: { atributosRespondidos: SaneamentoAtributoEntity[], atributoComNovoValor: boolean }) { }
}

export class Liberar {
    public static readonly type = '[SaneamentoAtributo] Liberar produto saneamento';
    constructor(public payload: { data?: any }) { }
}

export class Cancelar {
    public static readonly type = '[SaneamentoAtributo] Cancelar alteracoes';
    constructor() { }
}

export class Remover {
    public static readonly type = '[SaneamentoAtributo] Remover saneamentos';
    constructor() { }
}

export class AtualizarValoresSelecionados {
    public static readonly type = '[SaneamentoAtributo] Atualizar Valores Selecionados';
    constructor(public payload: { data: PdmAtributoValorSelecionado, remover: boolean }) { }
}

export class Exportar {
    public static readonly type = '[SaneamentoAtributo] Exportar preenchimento saneamento';
    constructor() { }
}

export class VerificarProdutosSelecionadosPossuiSaneamentoConcluido {
    public static readonly type = '[SaneamentoAtributo] Verificar se algum produto selecionado possui ao menos um saneamento concluído';
    constructor() { }
}

export class RemoverCancelar {
    public static readonly type = '[SaneamentoAtributo] Remover cancelar';
    constructor() { }
}

export class RemoverConfirmar {
    public static readonly type = '[SaneamentoAtributo] Remover confirmar ';
    constructor() { }
}

export class ObterAtributoReceitaDominios {
    public static readonly type = '[SaneamentoAtributo] Obter dominios atributo receita';
    constructor(public payload: { codigoAtributoPortalUnico: string }) { }
}

export class VerificarSugestaoPreenchimento {
    public static readonly type = '[SaneamentoAtributo] Verificar parâmetro de sugestão de preenchimento';
    constructor() { }
}

export class SugestaoPreenchimentoSaneamentos {
    public static readonly type = '[SaneamentoAtributo] Sugestão de preenchimento dos saneamentos';
    constructor() { }
}

export class Importar {
    public static readonly type = '[SaneamentoAtributo] Importar';
    constructor(public payload: { arquivo: string }) { }
}

export class AtualizarImportacaoStatus {
    public static readonly type = '[SaneamentoAtributo] Atualizar status de importação';
    constructor(public payload: string) { }
}

export class AtualizarExportacaoStatus {
    public static readonly type = '[SaneamentoAtributo] Atualizar status de exportação';
    constructor(public payload: string) { }
}

export class SalvarHubConnectionId {
    public static readonly type = '[SaneamentoAtributo] Salvar hub connection id';
    constructor(public payload: string) { }
}

export class ObterUltimaSocilitacaoImportacao {
    public static readonly type = '[SaneamentoAtributo] Obter Ultima Solicitacao Importacao';
    constructor() { }
}

export class ObterUltimaSocilitacaoExportacao {
    public static readonly type = '[SaneamentoAtributo] Obter Ultima Solicitacao Exportacao';
    constructor() { }
}

export class ObterGrupoImportacao {
    public static readonly type = '[SaneamentoAtributo] Obter Grupo Importação';
    constructor(public tipo: string) { }
}

export class ObterGrupoExportacao {
    public static readonly type = '[SaneamentoAtributo] Obter Grupo Exportação';
    constructor(public tipo: string) { }
}

export class DownloadRetornoImportacao {
    public static readonly type = '[SaneamentoAtributo] Download Arquivo Retorno Importação';
    constructor() { }
}

export class DownloadRetornoExportacao {
    public static readonly type = '[SaneamentoAtributo] Download Arquivo Retorno Exportação';
    constructor() { }
}

export class ObterUsuario {
    public static readonly type = '[SaneamentoAtributo] Obter Usuario';
    constructor() { }
}
