import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {MatDialogModule} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {ModalVisualizarComponent} from "./modal-visualizar.component";
import { CommonModule } from "@angular/common";
import { MatCardModule } from "@angular/material/card";
import { FormModule } from "../form";

@NgModule({
    declarations: [
        ModalVisualizarComponent
    ],
    imports: [
        MatDialogModule,
        MatButtonModule,
        CommonModule,
        MatCardModule,
        FormModule
    ],
    providers: [
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ModalVisualizarModule { }
