import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { SnackBarMultiLineComponent} from './snack-bar-multi-line/snack-bar-multi-line.component';

@NgModule({
  declarations: [
    SnackBarMultiLineComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule
  ],
  exports: [
    SnackBarMultiLineComponent
  ],

})
export class NotificationModule { }
