export const STATUS_ENVIO_INTEGRADO = 'Integrado';
export const STATUS_ENVIO_VALIDADO = 'Validado';
export const STATUS_ENVIO_RETIFICADO = 'Retificado';
export const STATUS_ENVIO_LIDO_POR_TERCEIRO = 'Lido por sistema terceiro';
export const STATUS_ENVIO_PENDENTE = 'Pendente';
export const STATUS_ENVIO_AGUARDANDO = 'Aguardando envio';
export const STATUS_ENVIO_PROBLEMA_ENVIO = 'Problema no envio';
export const STATUS_ENVIO_PROBLEMA_ENVIO_VALOR = 'ProblemaNoEnvio';
export const STATUS_ENVIO_ENVIANDO = 'Enviando';

export const TIPO_PENDENCIA_CADASTRO = 'Cadastro';
export const TIPO_PENDENCIA_INATIVAR = 'Inativar';

