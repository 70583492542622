import { BaseField } from '../base-field';

export class FileField<T = any> extends BaseField<T> {
  tiposAceitos = [];
  btnTexto = 'Selecione';
  prefixo = 'insert_file';
  sufixo = 'preview';
  constructor(init: Partial<FileField<T>>) {
    super('File', init);
    if (!init.css) {
      this.css = 'col-4';
    }
  }
}
