import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { UsuarioCertificadoModalSenhaService } from './usuario-certificado-modal-senha.service';
import { EnumTipoCertificadoEnvioPortalUnico } from '../../enums/enum-parametro-configuracao';

@Component({
    selector: 'usuario-certificado',
    templateUrl: './usuario-certificado-modal-senha.component.html',
    styleUrls: ['./usuario-certificado-modal-senha.component.scss'],
})
export class UsuarioCertificadoModalSenhaComponent implements OnInit {
    public senha = new FormControl('', [
        Validators.required
    ]);
    public esconderSenha: boolean = true;
    public tipoCertificadoPadrao = EnumTipoCertificadoEnvioPortalUnico.Padrao;
    public tipoCertificadoUsuario = EnumTipoCertificadoEnvioPortalUnico.Usuario;
    
    public opcaoCertificadoSelecionada = new FormControl( null,[
        Validators.required
    ]);
    
    public opcoesCertificado = [];
    
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { logar: boolean, solicitarSenha: boolean, certificadoUsuario: boolean, certificadoPadrao: boolean },
        @Inject(UsuarioCertificadoModalSenhaService) public service: UsuarioCertificadoModalSenhaService,
    ) {
        this.opcoesCertificado.push({
            id: EnumTipoCertificadoEnvioPortalUnico.Usuario,
            descricao: 'Usuário',
            desabilitado: !this.data.certificadoUsuario
        });
        
        if(this.data.logar){
            this.opcoesCertificado.push({
                id: EnumTipoCertificadoEnvioPortalUnico.Padrao,
                descricao: 'Padrão',
                desabilitado: !this.data.certificadoPadrao
            });
        }
        
        if(!this.data.certificadoUsuario && this.data.certificadoPadrao ){
            this.opcaoCertificadoSelecionada.setValue(EnumTipoCertificadoEnvioPortalUnico.Padrao);
        }else{
            this.opcaoCertificadoSelecionada.setValue(EnumTipoCertificadoEnvioPortalUnico.Usuario);
        }
    }
    
    ngOnInit(): void {
    }
}
