import { Inject, Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { PROCESSADOR_SOLICITACAO_URL } from "@catalogo/features/compartilhado/const/constantes";
import { Observable } from 'rxjs';
import { SolicitacaoHubMensagem } from '@catalogo/models/hubs/solicitacao-hub.mensagem';
import { BaseHubService } from "./base-hub.service";

export const TIPO_PROCESSAMENTO_OPERADOR = 'ImportarPlanilhaOperador';

@Injectable({
    providedIn: 'root'
})
export class ProcessadorSolicitacaoHubOperadorGrupoService extends BaseHubService{
    
    constructor(
        @Inject(PROCESSADOR_SOLICITACAO_URL) urlBase: string
    ) {
        super();
        this.hubConnection = new HubConnectionBuilder()
            .configureLogging(signalR.LogLevel.None)
            .withUrl(`${urlBase}/${TIPO_PROCESSAMENTO_OPERADOR}`)
            .withAutomaticReconnect()
            .build();
    }
    
    obterImportacaoOperadorStatus(): Observable<SolicitacaoHubMensagem> {
        return new Observable<SolicitacaoHubMensagem>((observer) => {
            this.hubConnection.on(TIPO_PROCESSAMENTO_OPERADOR, (notificacao: SolicitacaoHubMensagem) => {
                observer.next(notificacao);
            });
        });
    }
}
