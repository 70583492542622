export class GridTextField<T = any> {
    property: string = '';
    tooltip: string | ((e: T) => string) = '';
    title: string | ((e: T) => string) = '';
    label: string | ((e: T) => string) = '';
    mascara: string | ((e: T) => string) = '';
    value!: string | ((e: T) => any);
    esconder: boolean | ((e: T) => boolean) = false;
    classes: string | ((e: T) => string) = '';
    permiteOrdenar: boolean | (() => boolean) = true;
    multiLinhas: boolean | (() => boolean) = false;

    constructor(init: Partial<GridTextField>) {
        Object.assign(this, init);
    }
}
export class GridConfig<T = any> {
    fields: GridTextField[] = [];
    actionsTitle?: string;
    actions: Action<T>[] = [];

    mostrarSelecionarItem?: boolean;
    mostrarSelecionarTodos?: boolean;
    corLinha?: string | ((e: T) => string) = '';
    corFonte?: string | ((e: T) => string) = '';

    constructor(init: GridConfig<T>) {
        Object.assign(this, init);
    }
}

export class Action<T = any> {
    title: string = '';
    action!: (e: T) => void;
    icone?: string = '';
    corIcone?: string = '';
    classes?= 'w-acoes';
    esconder?: (e: T) => boolean;
    desabilitar?: (e: T) => boolean;
}
