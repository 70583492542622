<mat-form-field
  appearance="outline"
  [formGroup]="formGroup"
  style="margin-bottom: -1.25em; width: 100%; position: relative;"
>
  <mat-label>{{config.label}}</mat-label>
  <input
    matInput
    readonly
    class="cursor-pointer"
    [value]="fileName"
    (click)="fileInput.click()"
  />
  
  <input
    type="file"
    #fileInput
    id="uploadFile"
    (change)="onFileChanged($event)"
    [hidden]="true"
  />
</mat-form-field>
 
