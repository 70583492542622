import { ContaEmUsoModel } from '../conta-em-uso.model';

export class ObterContas {
  public static readonly type = '[ContaEmUso] Obter todas contas';
    constructor(public permicaoAmbiente: string) { }
}

export class AtualizarContas {
    public static readonly type = '[ContaEmUso] Atualizar contas';
    constructor(public payload: ContaEmUsoModel[]) { }
}

export class SelecionarConta {
  public static readonly type = '[ContaEmUso] Selecionar conta';
  constructor(public payload: ContaEmUsoModel) { }
}

export class FiltrarContas {
  public static readonly type = '[ContaEmUso] Filtrar contas';
  constructor(public payload: string) { }
}
