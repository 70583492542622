import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { first, map } from 'rxjs/operators';
import { SnackBarMultiLineComponent } from '../notification';

const durationDefault = 5000;
const verticalPositionDefault = 'top';
const horizontalPositionDefault = 'right';

@Injectable({ providedIn: 'root' })
export class NotificationService {
    enabledStack: boolean;
    public stack: {
        message: string | string[],
        actionMsg?: string,
        alertType?: string
        duration?: number
    }[] = [];

    constructor(
        private snackBar: MatSnackBar,
        private ngZone: NgZone
    ) { }

    public openSnackBarWithDispact(message: string, actionName: string, action) {
        this.snackBar.open(message, actionName)
            .onAction().pipe(first(), map(action)).subscribe();
    }

    public openSnackBar(message: string) {
        return this.ngZone.run(() => this.snackBar.open(message, '', {
            duration: durationDefault
        }));
    }

    public openSnackBarWaitable(message: string, action = 'OK') {
        this.snackBar.open(message, action, { duration: undefined })
            .onAction().pipe(first()).subscribe(() => this.snackBar.dismiss());
    }

    public openSuccess(message: string, actionMsg?: string) {
        this.openSnackBarAlert(message, actionMsg || 'OK', 'success', durationDefault);
    }

    public openError(message: string, actionMsg?: string) {
        this.openSnackBarAlert(message, actionMsg || 'OK', 'danger', undefined);
    }

    public openWarning(message: string, actionMsg?: string) {
        this.openSnackBarAlert(message, actionMsg || 'OK', 'warning', undefined);
    }

    public openInfo(message: string, actionMsg?: string) {
        this.openSnackBarAlert(message, actionMsg || 'OK', 'info', undefined);
    }

    private openSnackBarAlert(message: string, actionMsg: string, alertType: string, duration: number | undefined) {
        if (this.enabledStack) {
            this.stack.push({ message, actionMsg, alertType, duration });
            return;
        }

        this.ngZone.run(() => {
            this.snackBar.open(message, actionMsg, {
                duration,
                panelClass: ['alert', `alert-${alertType}`],
                verticalPosition: verticalPositionDefault,
                horizontalPosition: horizontalPositionDefault
            }).onAction().pipe(first()).subscribe(() => this.snackBar.dismiss());
        });
    }

    public openSuccessMultiLine(message: string[]) {
        this.openSnackBarAlertMultiLine(message, 'success', durationDefault);
    }

    public openErrorMultiLine(message: string[]) {
        this.openSnackBarAlertMultiLine(message, 'danger', undefined);
    }

    public startStackMessage() {
        this.enabledStack = true;
    }

    public hasStackMessage(): boolean {
        return this.stack.length > 0;
    }

    public stopStackMessage() {
        this.enabledStack = false;
        this.stack = [];
    }

    public openStackMessage() {
        let message = [];
        let alertType = 'success';
        let duration = undefined;

        if (this.stack.find(s => s.alertType === 'danger')) {
            alertType = 'danger';
        }

        if (this.stack.length > 0) {

            for (const item of this.stack) {
                if (item.message instanceof Array) {
                    for (let i = 0; i < item.message.length; i++) {
                        message.push(item.message[i]);
                    }
                } else {
                    message.push(item.message);
                }
                if (item.duration && (duration === undefined || item.duration > duration)) {
                    duration = item.duration;
                }
            }
            let stack = this.enabledStack;
            this.enabledStack = false;
            this.openSnackBarAlertMultiLine(message, alertType, duration);
            this.enabledStack = stack;
        }
    }

    private openSnackBarAlertMultiLine(message: string[], alertType: string, duration: number | undefined) {
        if (this.enabledStack) {
            this.stack.push({ message, alertType, duration });
            return;
        }

        this.ngZone.run(() => {
            this.snackBar.openFromComponent(SnackBarMultiLineComponent, {
                duration,
                panelClass: ['alert', `alert-${alertType}`],
                verticalPosition: verticalPositionDefault,
                horizontalPosition: horizontalPositionDefault,
                data: { message, alertType }
            });
        });
    }

    public closeSnackBar() {
        this.snackBar.dismiss();
    }
}
