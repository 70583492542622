import { Observable } from 'rxjs';
import { BaseField } from '../base-field';

export class SelectField<T = any> extends BaseField<T> {
  public values?: SelectFieldItem[];
  public useAsync?: boolean;
  public valuesAsync?: Observable<SelectFieldItem[]>;
  public onValueSelectedFunc?: (value: any) => any;
  public useValueSelected?: boolean;
  public valueSelected?: Observable<SelectFieldItem>;

  constructor(init: Partial<SelectField<T>>) {
    super('Select', init);
    if (!init.css) {
      this.css = 'col-4';
    }
  }
}
export interface SelectFieldItem {
  value: string;
  text: string;
  id: string;
}
