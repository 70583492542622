import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {ApiResult, OPERACAO_API_URL} from '../../services';
import { TipoNotificacao } from './notificacao.component';

@Injectable({
    providedIn: 'root'
})
export class NotificacaoDataService {

    protected url: string;

    constructor(
        @Inject(OPERACAO_API_URL) public urlBase: string,
        @Inject(HttpClient) protected httpClient: HttpClient
    ) {
        this.url = `${urlBase}/v1/Notificacao`
    }

    public obterPendentes = () => this.httpClient.get<ApiResult<any>>(`${this.url}`);

    public remover = (tipo: TipoNotificacao) => this.httpClient.delete<any>(`${this.url}/${tipo}`);
}
