<mat-dialog-content class="mat-typography">
    <h3>
        {{data.titulo}}
    </h3>
    <mat-card>
        <mat-card-content>
            <ui-form
                    [config]="data.configForm"
                    [entity]="data.entity"
                    [showBtn]="false">
            </ui-form>
        </mat-card-content>
    </mat-card>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button
            mat-flat-button
            color="primary"
            [mat-dialog-close]="true"
            (click)="data.fechar(data.entity)"
    >
        Fechar
    </button>
</mat-dialog-actions>
