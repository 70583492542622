import { MasterConfig } from 'ui-shared';
import { EnumRoles, Rules } from './features/compartilhado/roles-guard/rules';
import { environment } from "@env/environment";
import { EnumAmbiente } from "@env/enum-ambiente";

export const masterConfiguracao = {
    permicaoAmbiente: environment.permissaoAmbiente,
    multiConta: true,
    titulo: 'Catálogo de Produtos - Configuração',
    rota: '/config/home',
    imgUrl: '../assets/AF_ICONE_BLUE_ROUTE_WHITE.png',
    links: [
        { titulo: 'Home', icone: 'home', label: 'Home', rota: 'home' },
        {
            titulo: 'Configuracoes', icone: 'settings', label: 'Configurações', links: [
                { titulo: 'Parâmetros', icone: 'tune', label: 'Parâmetros', rota: 'parametro-valor' }
            ]
        },
        {
            titulo: 'Consultas', icone: 'search', label: 'Consultas', links: [
                {
                    titulo: 'Notificações Atributos RFB',
                    icone: 'article',
                    label: 'Notificações Atributos RFB',
                    rota: 'notificacoes-mensagens-agrupada'
                },
                {
                    titulo: 'NCM',
                    icone: 'description',
                    label: 'NCM',
                    rota: 'ncm'
                },
                {
                    titulo: 'Atributos Receita',
                    icone: 'analytics',
                    label: 'Atributos Receita',
                    rota: 'atributo-receita'
                }
            ]
        },
        {
            titulo: 'Cadastro', icone: 'source', label: 'Cadastro', links: [
                { titulo: 'Parâmetros', icone: 'settings', label: 'Parâmetros', rota: 'parametros' },
                { titulo: 'Clientes', icone: 'group', label: 'Clientes', rota: 'clientes' },
                { titulo: 'Relatórios', icone: 'description', label: 'Relatórios', rota: 'relatorios' },
                { titulo: 'Agências Emissoras Internacionais', icone: 'public', label: 'Agências Emissoras Internacionais', rota: 'agencia-emissora-internacional' },
                { titulo: 'Notificações', icone: 'notifications', label: 'Notificações', rota: 'notificacoes' },
                { titulo: 'País', icone: 'outlined_flag', label: 'País', rota: 'pais' },
                { titulo: 'Solicitações', icone: 'manage_history', label: 'Solicitações Processamento', rota: 'solicitacao-processamento' },
            ]
        },
    ],
} as MasterConfig;

export const masterSemContaEmUso = {
    permicaoAmbiente: environment.permissaoAmbiente,
    multiConta: true,
    titulo: 'Catálogo de Produtos',
    imgUrl: '../assets/AF_ICONE_BLUE_ROUTE_WHITE.png',
    links: [
        { titulo: 'Home', icone: 'home', label: 'Home', rota: 'home' }
    ],
} as MasterConfig;

export const masterOperacao = (rules: Rules, accessToken: string, ambiente: string, iconeDespachante?: string) => {
    return {
        permicaoAmbiente: environment.permissaoAmbiente,
        multiConta: true,
        notificacao: true,
        titulo: 'Catálogo de Produtos',
        rota: '/home',
        imgUrl: '../assets/AF_ICONE_BLUE_ROUTE_WHITE.png',
        ambiente: ambiente,
        iconeDespachante: iconeDespachante ? iconeDespachante : '',
        links: [
            { titulo: 'Home', icone: 'home', label: 'Home', rota: '/home' },
            {
                titulo: 'Treinamento',
                icone: 'school',
                label: 'Treinamento',
                rota: () => {
                    window.open(`${environment.catalogoOperacao.treinamento}/login#access_token=${accessToken}&token_type=Bearer`, "_blank")
                },
                hide: environment.ambiente === EnumAmbiente.Producao
            },
            {
                titulo: 'Configuracoes', icone: 'settings', label: 'Configurações', links: [
                    {
                        titulo: 'Parâmetro Operacao Valor',
                        icone: 'tune',
                        label: 'Parâmetros',
                        rota: '/parametro-operacao-valor',
                        disable: !rules.possuiPermissao(EnumRoles.Administrador)
                    },
                    // {
                    //     titulo: 'Mapeamentos de Atributos',
                    //     icone: 'map',
                    //     label: 'Map. de Atributos',
                    //     rota: '/mapeamento-atributos',
                    //     disable: !rules.possuiPermissao(EnumRoles.CadastroPdm)
                    // },
                    {
                        titulo: 'Gestão de Usuário',
                        icone: 'lock',
                        label: 'Gestão de Usuário',
                        rota: '/gestao-usuario',
                        disable: !rules.possuiPermissao(EnumRoles.Administrador)
                    },
                    {
                        titulo: 'Dados do Cliente',
                        icone: 'import_contacts',
                        label: 'Dados do Cliente',
                        rota: '/dados-cliente',
                        disable: !rules.possuiPermissao(EnumRoles.Administrador)
                    }
                ]
            },
            {
                titulo: 'Consultas', icone: 'search', label: 'Consultas', links: [
                    {
                        titulo: 'Notificações Atributos RFB',
                        icone: 'article',
                        label: 'Notificações Atributos RFB',
                        rota: 'notificacoes-mensagens-agrupada'
                    },
                    {
                        titulo: 'NCM',
                        icone: 'description',
                        label: 'NCM',
                        rota: '/ncm'
                    },
                    {
                        titulo: 'Atributos Receita',
                        icone: 'analytics',
                        label: 'Atributos Receita',
                        rota: '/atributo-receita'
                    }
                ]
            },
            {
                titulo: 'Cadastros', icone: 'source', label: 'Cadastros', links: [
                    {
                        titulo: 'Empresas',
                        icone: 'apartment',
                        label: 'Empresas',
                        rota: '/empresa',
                        disable: !rules.possuiPermissao(EnumRoles.CadastroOperacional)
                    },
                    {
                        titulo: 'Importação de Dados',
                        icone: 'publish',
                        label: 'Importação de Dados',
                        rota: '/importacao-dados',
                        disable: !rules.possuiPermissao(EnumRoles.CadastroOperacional, true) &&
                            !rules.possuiPermissao(EnumRoles.VinculacaoFabricante)
                    },
                    {
                        titulo: 'Certificado',
                        icone: 'admin_panel_settings',
                        label: 'Certificado',
                        rota: '/cliente-certificado',
                        disable: !rules.possuiPermissao(EnumRoles.Administrador)
                    },
                    {
                        titulo: 'Fabricante Nacional',
                        icone: 'factory',
                        label: 'Fabricante Nacional',
                        rota: '/fabricante-nacional',
                        disable: !rules.possuiPermissao(EnumRoles.CadastroOperacional)
                    },
                    {
                        titulo: 'Operador estrangeiro',
                        icone: 'directions_boat',
                        label: 'Operador Estrangeiro',
                        rota: '/operador-estrangeiro',
                        disable: !rules.possuiPermissao(EnumRoles.CadastroOperacional)
                    },
                    {
                        titulo: 'Unidade de Negócio',
                        icone: 'corporate_fare',
                        label: 'Unidade de Negócio',
                        rota: '/unidade-negocio',
                        hide: !rules.possuiPermissao(EnumRoles.Administrador)
                    },
                    {
                        titulo: 'Produto',
                        icone: 'inventory',
                        label: 'Produto',
                        rota: '/produto',
                        disable: !rules.possuiPermissao(EnumRoles.CadastroOperacional)
                    },
                    {
                        titulo: 'Tipo de Descrição',
                        icone: 'water',
                        label: 'Tipo de Descrição',
                        rota: '/tipo-descricao',
                        disable: !rules.possuiPermissao(EnumRoles.CadastroPdm)
                    },
                    {
                        titulo: 'PDM',
                        icone: 'developer_board',
                        label: 'PDM',
                        rota: '/pdm',
                        disable: !rules.possuiPermissao(EnumRoles.CadastroPdm)
                    },
                ]
            },
            {
                titulo: 'Saneamento', icone: 'format_shapes', label: 'Saneamento', links: [
                    {
                        titulo: 'Priorização',
                        icone: 'toc',
                        label: 'Priorização',
                        rota: '/saneamento/priorizacao',
                        disable: !rules.possuiPermissao(EnumRoles.SaneamentoAnalista)
                    },
                    {
                        titulo: 'Atributos',
                        icone: 'analytics',
                        label: 'Atributos',
                        rota: '/saneamento/saneamento-atributo',
                        disable: !rules.possuiPermissao(EnumRoles.SaneamentoAnalista)
                    },
                    {
                        titulo: 'Auditoria',
                        icone: 'fact_check',
                        label: 'Auditoria',
                        rota: '/saneamento/auditoria',
                        disable: !rules.possuiPermissao(EnumRoles.SaneamentoAuditor)
                    },
                    {
                        titulo: 'Atributos Modificados',
                        icone: 'notifications_none',
                        label: 'Atributos Modificados',
                        rota: '/atributos-modificados',
                        disable: !rules.possuiPermissao(EnumRoles.CadastroPdm)
                    }
                ]
            },
            {
                titulo: 'Catálogo Portal Único', icone: 'menu_book', label: 'Catálogo Portal Único', links: [
                    {
                        titulo: 'Versões Produtos',
                        icone: 'layers',
                        label: 'Versões Produtos',
                        rota: '/catalogo',
                        disable: !rules.possuiPermissao(EnumRoles.CadastroOperacional)
                    },
                    {
                        titulo: 'Pendências',
                        icone: 'cloud_upload',
                        label: 'Pendências',
                        rota: '/pendencias-envio',
                        disable: !rules.possuiPermissao(EnumRoles.CadastroOperacional)
                    }
                ]
            },
            { titulo: 'Relatórios', icone: 'area_chart', label: 'Relatórios', rota: '/relatorio' },
        ],
        usuarioLinks: [
            { titulo: 'Configurações', icone: 'tune', label: 'Configurações', rota: 'usuario/configuracao' }
        ],
    } as MasterConfig;
}
