export enum EnumTipoValor
{
    String = 'String',
    Inteiro ='Inteiro' ,
    Boolean = 'Boolean',
    Data = 'Data' ,
    Cadastro = 'Cadastro',
    Float = 'Float'
}

export const EnumTipoValorDescricao = (tipoValor: EnumTipoValor): string => {
    switch (EnumTipoValor[tipoValor]) {
        case EnumTipoValor.Cadastro: return EnumTipoValor.Cadastro.toString();
        case EnumTipoValor.Data: return EnumTipoValor.Data.toString();
        case EnumTipoValor.Boolean: return EnumTipoValor.Boolean.toString();
        case EnumTipoValor.String: return EnumTipoValor.String.toString();
        case EnumTipoValor.Inteiro: return EnumTipoValor.Inteiro.toString();
        case EnumTipoValor.Float: return EnumTipoValor.Float.toString();
        default: return "";
    }
}

export enum EnumTipoUso
{
    Configuracao = 'Configuracao',
    Operacional = 'Operacional',
    Global = 'Global'
}

export const EnumTipoUsoDescricao = (tipoUso: EnumTipoUso): string => {
    switch (EnumTipoUso[tipoUso]) {
        case EnumTipoUso.Operacional: return EnumTipoUso.Operacional.toString();
        case EnumTipoUso.Configuracao: return "Configuração";
        case EnumTipoUso.Global: return EnumTipoUso.Global.toString();
        default: return "";
    }
}

export enum EnumModulo
{
    CatalogoConfiguracao = 'CatalogoConfiguracao',
    CatalogoOperacao = 'CatalogoOperacao',
    Identidade = 'Identidade'
}

export const EnumModuloDescricao = (modulo: EnumModulo): string => {
    switch (EnumModulo[modulo]) {
        case EnumModulo.CatalogoConfiguracao: return "Catálogo Configuração";
        case EnumModulo.CatalogoOperacao: return "Catálogo Operação";
        case EnumModulo.Identidade: return "Identidade";
        default: return "";
    }
}

export enum EnumTipoCertificadoEnvioPortalUnico {
    Usuario = 'Usuario',
    Padrao = 'Padrao'
}
