<mat-dialog-content class="mat-typography">
    <h3>
        Certificado Digital
    </h3>

    <div style="display: flex;">
        <mat-radio-group ngDefaultControl [formControl]="opcaoCertificadoSelecionada" style="padding-right: 4em;">
            <mat-radio-button *ngFor="let opcao of opcoesCertificado" [value]="opcao.id" [disabled]="opcao.desabilitado">
                {{opcao.descricao}}
            </mat-radio-button>
        </mat-radio-group>
    </div>
    <mat-form-field style="min-width: 270px;" *ngIf="data.solicitarSenha && opcaoCertificadoSelecionada.value != tipoCertificadoPadrao">
        <mat-label>Senha</mat-label>
        <input matInput [formControl]="senha" placeholder="Informe a senha do certificado"
               [type]="esconderSenha ? 'password' : 'text'" />
        <mat-icon matSuffix (click)="esconderSenha = !esconderSenha">
            {{esconderSenha ? 'visibility_off' : 'visibility'}}
        </mat-icon>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-flat-button [mat-dialog-close]="true" (click)="service.fechar()">
        Cancelar
    </button>
    <button type="submit" (click)="service.confirmar(senha.value, data.logar, opcaoCertificadoSelecionada.value)"
            mat-flat-button color="primary"
            [disabled]="opcaoCertificadoSelecionada.value === null ||
            opcaoCertificadoSelecionada.value == tipoCertificadoUsuario && data.solicitarSenha &&
            (senha.invalid || (!senha.dirty || !senha.touched))"
    >
        Confirmar
    </button>
</mat-dialog-actions>
