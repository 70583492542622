import { EventEmitter, Output } from '@angular/core';
import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Link } from '../../models';

@Component({
  selector: 'ui-master-item',
  templateUrl: './master-item.component.html',
  styleUrls: ['./master-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MasterItemComponent implements OnInit {

  @Input() link!: Link;
  public panelOpenState: boolean = false;

  @Output() handleLinkClick = new EventEmitter<Link>();

  constructor() { }

  ngOnInit(): void {
  }

  getRota(link: Link): string | undefined {
      return link.rota instanceof Function ? undefined : link.rota;
  }

    execRota(link: Link){
        if(link.rota instanceof Function) {
            link.rota();
        }
    }

}
