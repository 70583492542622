import { Component } from "@angular/core";
import { NotificacaoService } from "./notificacao.service";

@Component({
    selector: 'ui-notificacao',
    templateUrl: './notificacao.component.html',
    styleUrls: ['./notificacao.component.scss'],
})

export class NotificacaoComponent {

    constructor(public service: NotificacaoService) {
        this.service.obterPendentes();
    }

    // #TODO - Refatorar esse componente de forma genérica e deixar a regra de negócio no Back
    public obterMensagemTipo(tipo: string) {
        switch (tipo) {
            case TipoNotificacao.MudancaAtributoRfb:
                return "Existem Produtos que sofreram mudanças nos Atributos da RFB";
            case TipoNotificacao.ProcessamentoImportacaoProdutos:
                return "Processamento de Importação de Produtos concluído";
            case TipoNotificacao.ProcessamentoImportacaoOperadores:
                return "Processamento de Importação de Operador Estrangeiro concluído";
            case TipoNotificacao.ProcessamentoSugestaoPreenchimentoSaneamento:
                return "Processamento de Sugestão de Preenchimento do Saneamento concluído";
            case TipoNotificacao.ProcessamentoImportacaoPreenchimentoSaneamento:
                return "Processamento de Importação de Preenchimento do Saneamento";
            case TipoNotificacao.ProcessamentoExportacaoPreenchimentoSaneamento:
                return "Processamento de Exportação de Preenchimento do Saneamento";
            default:
                break;
        }
        return;
    }
}

export enum TipoNotificacao {
    MudancaAtributoRfb = "MudancaAtributoRfb",
    ProcessamentoImportacaoProdutos = "ProcessamentoImportacaoProdutos",
    ProcessamentoImportacaoOperadores = "ProcessamentoImportacaoOperadores",
    ProcessamentoSugestaoPreenchimentoSaneamento = "ProcessamentoSugestaoPreenchimentoSaneamento",
    ProcessamentoImportacaoPreenchimentoSaneamento = "ProcessamentoImportacaoPreenchimentoSaneamento",
    ProcessamentoExportacaoPreenchimentoSaneamento = "ProcessamentoExportacaoPreenchimentoSaneamento",    
}
