import { Inject, Injectable, NgZone } from '@angular/core';
import { Action, State, StateContext, Store } from '@ngxs/store';
import * as actions from './conta-em-uso.actions';
import { ContaEmUsoStateModel } from './conta-em-uso-state-model';
import { ContaEmUsoDataService } from '../conta-em-uso.data-service';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ContaEmUsoModel } from '../conta-em-uso.model';
import { UsuarioToken, UsuarioPermissoes } from '../../models';
import { DOCUMENT } from '@angular/common';
import { LoginService } from '../../../services';
import { Router } from '@angular/router';
import { of } from 'rxjs';

const contaSuporte = { id: '-1', nome: 'Suporte' } as ContaEmUsoModel;

@Injectable()
@State<ContaEmUsoStateModel>({
    name: 'ContaEmUso',
    defaults: {
        contas: [],
        filtro: '',
        selecionada: null
    }
})
export class ContaEmUsoState {
    constructor(
        private dataService: ContaEmUsoDataService,
        private loginService: LoginService,
        private store: Store,
        private router: Router,
        private zone: NgZone,
        @Inject(DOCUMENT) private document: Document
    ) {
    }

    //TODO Remover de dentro do componente de contas e implementar mo módulo que utiliza contas
    @Action(actions.ObterContas)
    public obterContas({ patchState, dispatch }: StateContext<ContaEmUsoStateModel>, { permicaoAmbiente }: actions.ObterContas) {
        return this.dataService.obter(permicaoAmbiente)
            .pipe(
                switchMap(({ data }) =>
                    this.loginService.token$.pipe(
                        map((token: UsuarioToken | null) => {
                            if (!token) return data;
                            const { suporte, contaEmUso } = token;
                            if (contaEmUso?.id) {
                                const conta = data!.find(x => x.id === contaEmUso.id);
                                patchState({ selecionada: conta });
                            }
                            else if (suporte) {
                                patchState({ selecionada: contaSuporte });
                            }
                            else if (!suporte && data!.length === 1) {
                                dispatch(new actions.SelecionarConta(data![0]));
                            }
                            return suporte ? [contaSuporte, ...data!] : data
                        }))),
                tap(contas => {
                    patchState({ contas });
                }));
    };

    @Action(actions.AtualizarContas)
    public atualizarContas({ patchState }: StateContext<ContaEmUsoStateModel>, { payload }: actions.AtualizarContas) {
        patchState({ contas: payload });
    }

    @Action(actions.FiltrarContas)
    public filtrarContas({ patchState }: StateContext<ContaEmUsoStateModel>, { payload }: actions.FiltrarContas) {
        patchState({ filtro: payload });
    }

    @Action(actions.SelecionarConta)
    public selecionarConta({ getState, patchState }: StateContext<ContaEmUsoStateModel>, { payload }: actions.SelecionarConta) {
        if (payload.rota) {
            patchState({ selecionada: payload });
            this.document.location.href = this.document.location.origin + payload.rota;
            return of(payload);
        }

        return this.dataService.atualizarToken(payload.id).pipe(
            map(result => {
                this.loginService.salvarToken(result.data!.accessToken);
                this.loginService.salvarUsuario(result.data!.usuarioAutenticado);
                const contas = getState().contas;
                patchState({ contas, selecionada: payload });
            }),
            switchMap(() => {
                if (payload.id != contaSuporte.id) {
                    return this.dataService.obterPermissoes(this.loginService.obterUsuario().email).pipe(
                        map(result => {
                            let usuario: UsuarioPermissoes = result.data;
                            if (usuario.multiempresa) {
                                usuario.perfis.push({
                                    id: usuario.id,
                                    usuarioId: usuario.id,
                                    perfilAcesso: 'Multiempresa',
                                    permiteEdicao: true
                                });
                            }
                            this.loginService.salvarPermissoes(usuario);
                        })
                    )
                }
                return of(payload);
            }),
            switchMap(() => {
                if (payload.id != contaSuporte.id) {
                    return this.dataService.atualizarAcessoUsuario(this.loginService.obterUsuario().email).pipe(
                        catchError(err => of(err.status)),
                    )
                }
                return of(payload);
            }),
            switchMap(() => this.document.location.href = this.document.location.origin + '/login')
        );
    }
}
