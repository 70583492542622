import {
  Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter
} from '@angular/core';

@Component({
  selector: 'ui-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileUploadComponent implements OnInit {
  @Input() tiposAceitos = [];
  @Input() btnTexto = 'Selecione';
  @Output() upload = new EventEmitter<FormData>();

  fileAttr = 'escolha um arquivo';
  files = [];
  constructor() { }

  ngOnInit(): void {
  }

  callUploadService(event: any) {
    this.upload.emit(event);
  }
}
