import { FocusMonitor } from '@angular/cdk/a11y';
import { Component, OnInit, ChangeDetectionStrategy, Input, ElementRef } from '@angular/core';
import { BaseFieldDirective } from '../base-field.directive';
import { CheckboxField } from './CheckboxField';

@Component({
  selector: 'ui-checkbox-field',
  templateUrl: './checkbox-field.component.html',
  styleUrls: ['./checkbox-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxFieldComponent extends BaseFieldDirective implements OnInit {

  @Input() public config!: CheckboxField;

  constructor(protected fmRef: FocusMonitor, protected elRef: ElementRef<HTMLElement>) {
    super(fmRef, elRef);
  }

  ngOnInit() {
    super.ngOnInit();
  }


}
