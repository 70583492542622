import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {MatDialogModule} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {ModalConfirmarComponent} from "./modal-confirmar.component";

@NgModule({
    declarations: [
        ModalConfirmarComponent
    ],
    imports: [
        MatDialogModule,
        MatButtonModule
    ],
    entryComponents: [
        ModalConfirmarComponent
    ],
    providers: [
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ModalConfirmarModule { }
