import {Component, Inject} from '@angular/core';
import {UsuarioCertificadoRemoverService} from './usuario-certificado-modal-remover.service';

@Component({
    templateUrl: './usuario-certificado-modal-remover.component.html',
    styleUrls: ['./usuario-certificado-modal-remover.component.scss']
})
export class UsuarioCertificadoModalRemoverComponent {

    constructor(
        @Inject(UsuarioCertificadoRemoverService) public service: UsuarioCertificadoRemoverService
    ) { }

}
