import {FocusMonitor} from '@angular/cdk/a11y';
import {HttpClient} from '@angular/common/http';
import {ChangeDetectionStrategy, Component, ElementRef, Inject, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {API_URL, ApiResult} from '../../../services';
import {BaseFieldDirective} from '../base-field.directive';
import {EnumField} from './enum-field';

@Component({
    selector: 'ui-enum-field',
    templateUrl: './enum-field.component.html',
    styleUrls: ['./enum-field.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EnumFieldComponent extends BaseFieldDirective implements OnInit {

    @Input() public config!: EnumField;
    values$!: Observable<EnumResult[] | undefined>;

    constructor(
        //TODO Remover URL e adicionar parâmetro para receber a função de busca dos enums
        @Inject(API_URL) protected apiUrl: string,
        protected httpClient: HttpClient,
        protected fmRef: FocusMonitor,
        protected elRef: ElementRef<HTMLElement>) {
        super(fmRef, elRef);
    }

    ngOnInit() {
        super.ngOnInit();
        this.values$ = this.httpClient
            .get<ApiResult<EnumResult[]>>(`${this.apiUrl}/v1/shared/enums/${this.config!.enum}`)
            .pipe(
                map(r => r.data)
            );
    }
}
export interface EnumResult {
    value: string;
    text: string;
    id: number;
}
