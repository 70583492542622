import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { FilterSearchQuerySelected } from './models/filter-search-query-selected.entity';
import { FilterEntity } from './models/filter.entity';

@Component({
  selector: 'ui-filter-search',
  templateUrl: './filter-search.component.html',
  styleUrls: ['./filter-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterSearchComponent implements OnInit {

  @Output() public submitEvent = new EventEmitter();

  @Input() public placeHolder = 'Pesquisar';
  @Input() public labelTermo!: string;
  @Input() public termo!: string;
  @Input() public selecionados!: FilterSearchQuerySelected;
  @Input() public filtrosAdicionais: { label: string; property: string; filtro: FilterEntity[] }[] = [];
  @Input() public temFiltroPrioridade: boolean = false;

  public form!: FormGroup;

  constructor(
      private formBuilder: FormBuilder
  ) {
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      termo: [this.termo || ''],
      prioridade: [''],
      ...this.filtrosAdicionais.reduce((a: any, x: any) => {
        a[x.property] = this.formBuilder.control('');
        return a;
      }, {})
    });
    if (this.selecionados) {
      this.form.patchValue(this.selecionados);
    }
  }

  submit() {
    const naoTemValor = this.form.invalid || this.form.untouched;
    this.submitEvent.emit(naoTemValor ? undefined : this.form.value);
  }

  limpaForm() {
    this.form.reset();
    this.form.markAsUntouched();
    Object.keys(this.form.controls).forEach(key => {
      const formControl = this.form.get(key);
      if(formControl !== null){
        formControl.setErrors(null);
        formControl.markAsUntouched();
      }
    });
  }

  getForm(name: string): FormControl {
    return this.form.get(name) as FormControl;
  }
}
