<button
  mat-button
  [matMenuTriggerFor]="menu"
  *ngIf="service.contas$ | async as contas; else loader"
  [disabled]="contas.length < 2"
>
  <mat-icon>switch_account</mat-icon>
  {{(service.conta$ | async)?.nome || 'Escolha uma conta' }}
</button>
<mat-menu #menu="matMenu">
  <ui-search
    placeHolder="Digite o nome da conta"
    (submitEvent)="service.filtrar($event)"
    (click)="naoFecharMenu($event)"
  ></ui-search>
  <button
    mat-menu-item
    role="menuitemcheckbox"
    *ngFor="let conta of service.contasFiltradas$ | async"
    (click)="service.selecionaConta(conta)"
  >
    {{conta.nome}}
  </button>
  <ng-template #loader>
    <mat-progress-spinner [diameter]="20" ></mat-progress-spinner>
  </ng-template>
</mat-menu>
