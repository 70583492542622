import { FiltroOpcao } from "./filtro-opcao.model";

export class Filtro {
    public descricao: string;
    public propriedade: string;
    public tipo: 'Text' | 'Number' | 'Boolean' | 'Date' | 'Enum' | 'Options' | 'Guid';
    public opcoes?: Array<FiltroOpcao>;
    public pesquisaEmLista?: boolean;
    public formatoSql?: boolean;
}
