<button
  mat-button
  (click)="service.login()"
  *ngIf="!(service.token$ | async);else logado"
>
  <mat-icon>power_settings_new</mat-icon>
</button>

<ng-template #logado>
  <button [hidden]="!links" mat-button [matMenuTriggerFor]="menu">
    <mat-icon>person_outline</mat-icon>
    {{ (service.token$ | async)?.usuario }}
  </button>
  <button [hidden]="links" mat-button>
    <mat-icon>person_outline</mat-icon>
    {{ (service.token$ | async)?.usuario }}
  </button>
  <button mat-button (click)=" service.logout()">
    <mat-icon>exit_to_app</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button
      *ngFor="let item of links"
      mat-menu-item
      role="menuitemcheckbox"
      [routerLink]="item.rota"
    >
      <mat-icon>{{item.icone}}</mat-icon>
      {{item.label}}
    </button>
  </mat-menu>
</ng-template>
