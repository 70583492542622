import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormGroup, ValidationErrors, } from '@angular/forms';

import { BaseField } from '../fields/base-field';

@Component({
    selector: 'ui-form-field-renderer',
    templateUrl: './form-field-renderer.component.html',
    styleUrls: ['./form-field-renderer.component.scss'],
})
export class FormFieldRendererComponent implements OnInit, OnChanges {

    @Input() field!: BaseField | any;
    @Input() public entity!: any;
    @Input() formGroup!: FormGroup;
    @Input() item: any;
    @Input() isVisible = true;
    @Input() condicional?: (f: FormGroup | undefined) => void;
    @Input() set disabled(val: any) {
        val ? this.controle.enable() : this.controle.disable();
    }
    
    public get controle(): AbstractControl {
        return this.formGroup!.get(this.field!.property);
    }

    public get errors(): ValidationErrors[] {
        let errors: ValidationErrors[] = [];
        
        if(this.controle instanceof FormGroup){
            const controls = (this.controle as FormGroup).controls;
            
            for (const formKey in controls) {
                let validation = controls[formKey].errors;
                if(validation) {
                    let key = Object.keys(validation)[0];
                    if (!errors.find((v) => Object.keys(v)[0] === key)) {
                        errors.push(validation);
                    }
                }
            }
        }else if(this.controle.errors){
            errors.push(this.controle.errors);
        }
        
        return errors;
    }

    constructor() { }
    ngOnChanges(changes: SimpleChanges): void {
        this.condicional && this.condicional(this.formGroup);
    }

    ngOnInit(): void {
    }

    validatorMessage(error: ValidationErrors): string {
        const campo = this.field?.label;
        if (error['required']) {
            return `${campo} é obrigatório`;
        }

        if (error['minlength']) {
            const { requiredLength, actualLength } = error['minlength'];
            return `${campo} deve ter no mínimo ${requiredLength} caracteres, atual ${actualLength}`;
        }

        if (error['maxlength']) {
            const { requiredLength, actualLength } = error['maxlength'];
            return `${campo} deve ter no máximo ${requiredLength} caracteres, atual ${actualLength}`;
        }

        if (error['pattern']) {
            return `Valor não é válido`;
        }

        if (error['min']) {
            const { min, actual } = error['min'];
            return `${campo} deve ter no mínimo ${min} caracteres, atual ${actual}`;
        }

        if (error['max']) {
            const { max, actual } = error['max'];
            return `${campo} deve ter no máximo ${max} caracteres, atual ${actual}`;
        }

        if (error['email']) {
            return `E-mail não é válido`;
        }

        return '';
    }
}
