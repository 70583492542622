import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpEventType,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { filter, tap } from 'rxjs/operators';
import { LocalStorageUtil } from './util/localstorage-util';

@Injectable()
export class JWTInterceptor implements HttpInterceptor {

  constructor(private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = LocalStorageUtil.obterTokenUsuario();
    if (token) {
      req = req.clone({
        setHeaders: {
          authorization: `Bearer ${token}`
        }
      });
    }

    return next.handle(req).pipe(
      filter((event: HttpEvent<any>) => event.type === HttpEventType.Response),
      tap((response: HttpEvent<any>) => {
        const event = response as HttpResponse<any>;
        if (event.status === 401) {
          this.router.navigate(['/login']);
        } else if (event.status === 403) {
          this.router.navigate(['/forbidden']);
        }
      }));
  }
}
