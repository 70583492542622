import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { FormComponent } from './form.component';
import { MatIconModule } from '@angular/material/icon';
import { FormFieldRendererModule } from './form-field-renderer/form-field-renderer.module';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  declarations: [
    FormComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    NgxMaskModule.forRoot(),
    FormFieldRendererModule
  ],
  exports: [
    FormComponent
  ],

})
export class FormModule { }
