import { BaseField } from '../base-field';

export class EnumField<T = any> extends BaseField<T> {

  enum!: string;

  constructor(init: Partial<EnumField<T>>) {
    super('Enum', init);
    if (!init.css) {
      this.css = 'col-4';
    }
  }
}
