export class LoginResponse {
  constructor(
    public accessToken: string,
    public expiresIn: number,
    public usuarioAutenticado: UsuarioAutenticado,
    public twoFactorEnabled: boolean
  ) { }
}
export class UsuarioAutenticado {
  constructor(
    public id: string,
    public email: string,
    public suporte: boolean,
    public nome: string,
    public ativo: boolean,
    public contas: Conta[],
    public claims: Claim[],
  ) { }
}

export class Conta {
  constructor(
    public conta: string,
    public administrador: boolean
  ) { }
}

export class Claim {
  constructor(
    public value: string,
    public type: string
  ) { }
}

export interface UsuarioToken {
  id: string;
  email: string;
  jti: string;
  nbf: number;
  iat: number;
  suporte: boolean;
  usuario: string;
  contaEmUso: { id: string; nome: string; ativo: boolean };
  exp: number;
  iss: string;
  aud: string;
}

export interface UsuarioTokenDecoded {
  sub: string;
  email: string;
  jti: string;
  nbf: number;
  iat: number;
  suporte: string;
  usuario: string;
  contaEmUso: string;
  exp: number;
  iss: string;
  aud: string;
}
//TODO: Mover lógica de negócio para o catalogo de produto
export class UsuarioPermissoes {
  public id: string;
  public contaId: string;
  public ativo: boolean;
  public administrador: boolean;
  public email: string;
  public nome: string;
  public userName: string;
  public multiempresa: boolean = false;
  public perfis: PerfilAcesso[];
}

export class PerfilAcesso {
  public id: string;
  public usuarioId: string;
  public permiteEdicao: boolean;
  public perfilAcesso: string;
}
